<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 18:10:10
 * @LastEditTime: 2021-06-10 17:09:19
-->
<template>
	<div class="abnormal">
		<div class="abnormal_inner">
			<img src="@/assets/images/common/404.png" class="abnormal_img">
			<div class="abnormal_desc">
				<span class="desc_top">404</span>
				<span class="desc_middle">糟糕！页面找不到了！</span>
				<span class="desc_bottom">让我门去另一个页面寻找真相吧！</span>
				<div class="desc_btn">
					<span @click="handlerFn('back')" class="desc_back">返回首页</span>
					<span @click="handlerFn('refresh')" class="desc_refresh">立即刷新</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {}
		},
		methods: {
			handlerFn(type) {
				switch (type) {
					case "back":
						this.$router.push({
							path: "/",
						});
						break;
					case "refresh":
						window.location.reload();
						break;
				}
			}
		},
	}
</script>
<style lang="scss" scoped>
	@import "../../styles/index.scss";
	.abnormal {
		width: 100%;
		height: 100vh;
		background: #FFFFFF;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;

		.abnormal_inner {
			display: flex;
			align-items: center;

			.abnormal_img {
				width: 240px;
				height: 240px;
				margin-right: 40px;
			}

			.abnormal_desc {
				display: flex;
				flex-direction: column;

				.desc_top {
					font-size: 60px;
					font-family: Source Han Sans CN;
					font-weight: bold;
					line-height: 87px;
					color: #333333;
				}

				.desc_middle {
					font-size: 20px;
					font-family: PingFang SC-Bold, PingFang SC;
					font-weight: bold;
					color: #303133;
					line-height: 28px;
					margin: 8px 0;
				}

				.desc_bottom {
					font-size: 16px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #909399;
					line-height: 22px;
					margin-bottom: 32px;
				}

				.desc_btn {
					display: flex;
					align-items: center;

					.desc_back {
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						line-height: 34px;
						color: $theme_color;
						text-align: center;
						width: 104px;
						background: #FFFFFF;
						border: 1px solid $theme_color;
						border-radius: 4px;
						cursor: pointer;
						margin-right: 16px;
					}

					.desc_refresh {
						width: 104px;
						line-height: 34px;
						background: $theme_color;
						border-radius: 4px;
						font-size: 14px;
						font-family: Source Han Sans CN;
						font-weight: 500;
						color: #FFFFFF;
						text-align: center;
						cursor: pointer;
					}
				}
			}
		}
	}
</style>
